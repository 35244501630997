import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import qs from 'query-string';

const MainCategories = [
  {
    label: '委員會',
    value: 'STC',
  },
  {label: '專業領域委員會', value: 'SFC'},
  {label: '社團', value: 'SUBGRP'},
  {label: '特別委員會', value: 'SC'},
];

export default function CommitteeListPage(props) {
  const params = qs.parse(props.location.search);
  const [committeeSummary, setCommitteeSummary] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState(params.cat || 'STC');

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const results = await JStorage.fetchAllDocuments(
          'Committee_Summary',
          {
            $and: [{CATEGORY: selectedTab}, {public: true}],
          },
          ['created'],
        );

        setCommitteeSummary(results);
      } catch (ex) {
        console.warn('CommitteePage ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [selectedTab]);

  return (
    <Wrapper selectedTab={selectedTab}>
      <main>
        <h1>委員會與社團</h1>
        <div className="tab-header">
          {MainCategories.map((cat, index) => {
            const selected = cat.value === selectedTab;
            return (
              <div
                className={`item index-${index}`}
                key={cat.value}
                onClick={() => {
                  setSelectedTab(cat.value);
                  AppActions.navigate(`/committees?cat=${cat.value}`);
                }}>
                {cat.label}
              </div>
            );
          })}
        </div>

        <div className="groups">
          {committeeSummary.length === 0 && (
            <h2 style={{textAlign: 'center', width: '100%'}}>敬請期待</h2>
          )}
          {committeeSummary.map((cs, index) => {
            return (
              <div
                className="item"
                key={`cs-${index}`}
                onClick={() =>
                  AppActions.navigate(
                    `/committee?name=${encodeURIComponent(cs.NAME)}`,
                  )
                }>
                <span>{cs.NAME}</span>
              </div>
            );
          })}
          <div className="fake-item" />
          <div className="fake-item" />
          <div className="fake-item" />
          <div className="fake-item" />
        </div>
      </main>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  & > main {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px;

    & > h1 {
      margin-bottom: 20px;
      text-align: center;
    }

    & > div.tab-header {
      display: flex;
      margin-bottom: 20px;

      & > div.item {
        flex: 1;

        cursor: pointer;
        border-bottom: 5px solid #969696;
        padding: 5px;

        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }

      ${(props) =>
        `& > div.index-${MainCategories.findIndex(
          (cat) => cat.value === props.selectedTab,
        )} {  border-bottom: 5px solid var(--secondColor); }`}
    }

    & .groups {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      & > .item {
        margin: 10px;
        width: 224px;
        height: 100px;

        padding: 5px;
        font-size: 1.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 500;
        color: var(--primaryColor);
        border: 1px solid var(--primaryColor);

        & > span {
          max-width: 140px;
          width: 100%;
          text-align: center;
        }

        :hover {
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }

      .fake-item {
        margin: 10px;
        width: 224px;
        height: 0px;
      }
    }
  }
`;
